<template>
  <div class="page-content">
    <section class="home-header">
      <div class="home-header-container con-wrap">
        <h1>聚焦用户行为的大数据分析平台</h1>
        <p>提供业务场景数据解决方案 助力企业实现数据驱动</p>
        <div class="home-btn">
          <Button
            data-agl-cvt="6"
            class="section-btn-demo"
            @click="demo"
            :onclick="'return gtag_report_conversion(' + openUrl + ')'"
          >
            体验Demo
          </Button>
          <!-- v-preventReClick="5000"		// 防止重复点击 -->
          <!-- style="display: none" -->
          <Button
            :style="isLogin ? 'display: none' : ''"
            data-agl-cvt="5"
            class="section-btn-reg"
            @click="reg"
            :onclick="'return gtag_report_conversion(' + openUrl + ')'"
          >
            免费注册
          </Button>
        </div>
      </div>
    </section>
    <section class="home-header">
      <div class="home-container con-wrap">
        <img src="../assets/images/home/编组 7@2x.png" alt="" />
        <div>
          <h3>{{ title1 }}</h3>
          <p>{{ text1 }}</p>
        </div>
      </div>
    </section>
    <section class="home-header">
      <div class="home-container con-wrap">
        <div>
          <h3>{{ title2 }}</h3>
          <p>{{ text2 }}</p>
        </div>
        <img src="../assets/images/home/编组 3@2x.png" alt="" />
      </div>
    </section>

    <section class="home-header">
      <div class="home-container-trade con-wrap">
        <h1 class="section-title">{{ title3 }}</h1>
        <p class="tradeText">{{ text3 }}</p>
        <div class="con-center">
          <div v-for="icon in tradeList" :key="icon._uid" class="icon-item">
            <img :src="icon.imgSrc" :alt="icon.text" />
            <h4>{{ icon.title }}</h4>
          </div>
        </div>
      </div>
    </section>
    <section class="home-header">
      <div class="home-container-bus con-wrap">
        <h1 class="section-title">{{ title4 }}</h1>
        <div class="icon-list con-center">
          <div
            v-for="icon in businessList"
            :key="icon._uid"
            class="icon-item-bus"
          >
            <img :src="icon.imgSrc" :alt="icon.text" />
          </div>
        </div>
      </div>
    </section>

    <!-- 产品主要特征 -->
    <icon-list-section
      :section-title="firstTitle"
      :icon-list="iconList"
    ></icon-list-section>

    <!-- 实现数据驱动完整流程 -->
    <img-section
      :section-title="secondTitle"
      :img-src="secondImgSrc"
    ></img-section>

    <section class="home-header">
      <div class="home-container-start con-wrap">
        <h1>开启你的数据驱动之旅</h1>
        <Button
          data-agl-cvt="6"
          class="section-btn-demo start"
          @click="demo"
          :onclick="'return gtag_report_conversion(' + openUrl + ')'"
        >
          立即开启
        </Button>
      </div>
    </section>

    <div class="service">
      <div v-show="seek" class="service-box">
        <img
          @click="close"
          class="serviceClose"
          src="../assets/images/home/close@2x.png"
          alt=""
        />
        <h4>有什么可以帮您吗？</h4>
        <div class="seek">
          <div @click="seekqq">
            <img src="../assets/images/home/咨询@2x.png" alt="在线咨询" />
            <span class="desTitle">在线咨询</span>
          </div>
          <div @click="showWeiin">
            <img src="../assets/images/home/weixin@2x.png" alt="客服微信" />
            <span class="desTitle">客服微信</span>
          </div>
        </div>
      </div>
      <div v-show="weixin" class="weixin">
        <img
          class="weixinClose"
          @click="close"
          src="../assets/images/home/close@2x.png"
          alt=""
        />
        <img class="qrcode" src="../assets/images/home/qrcode@2x.png" alt="" />
        <p>扫描二维码，添加客服微信</p>
      </div>
      <img
        @click="seekShow"
        class="serviceImg"
        src="../assets/images/home/service@2x.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import IconListSection from "@c/IconListSection";
import ImgSection from "@c/ImgSection";
import api from "../libs/api";
import Cookies from "js-cookie";

export default {
  name: "Home",
  components: {
    IconListSection,
    ImgSection,
  },
  props: {
    isLogin: Boolean,
  },
  data() {
    return {
      openUrl: /localhost|dev/.test(location.origin)
        ? "'http://devwebsite.tutordata.cn/'"
        : /uat/.test(location.origin)
        ? "'http://uatwebsite.tutordata.cn/'"
        : "'https://tutordata.cn/'",
      userInfo: Cookies.get("userInfo"),
      loading: false,
      seek: true, //咨询
      weixin: false, // 微信
      firstTitle: "产品主要特征",
      iconList: [
        {
          title: "全面",
          text: "支持iOS/Android/Web/H5/Mac/Windows等多个平台数据采集",
          imgSrc: require("../assets/images/icon_01.png"),
        },
        {
          title: "灵活",
          text: "User 与 Event 逻辑的双重加持，抽象用户行为，满足多维度交叉行为分析",
          imgSrc: require("../assets/images/icon_02.png"),
        },
        {
          title: "精准",
          text: "洞察单一用户特征及行为序列，从宏观到微观层面还原用户使用场景",
          imgSrc: require("../assets/images/icon_03.png"),
        },
        {
          title: "安全",
          text: "采用加密传输协议和私有化部署，充分保障数据安全，协助累计数据资产",
          imgSrc: require("../assets/images/icon_04.png"),
        },
        {
          title: "实时",
          text: "精确至秒级的数据查询，随时获取最新数据，避免决策延后",
          imgSrc: require("../assets/images/icon_05.png"),
        },
        {
          title: "开放",
          text: "多种开放的数据接口，支持二次开发，个性化定制数据分析",
          imgSrc: require("../assets/images/icon_06.png"),
        },
      ],
      secondTitle: "实现数据驱动完整流程",
      secondImgSrc: require("../assets/images/bg_2.png"),

      title1: "专业的数据可视化方案",
      text1:
        "企业将数据导入图拓后，通过先进的分析模型，结合多维度指标一键可视化展示，核心数据一览便知。",

      title2: "不同场景下的数据解决方案",
      text2:
        "产品功能、运营管理、市场营销，无需跨部门导出数据，支持自定义私人数据看板，业务情况随时查看。",

      title3: "满足多种行业分析需求",
      text3:
        "覆盖电商、互联网金融，视频直播等多个行业，满足复杂场景下的行业分析需求",
      bgImg: require("../assets/images/home/bg@2x.png"),
      tradeList: [
        {
          title: "开发者",
          imgSrc: require("../assets/images/home/hangye_1@2x.png"),
        },
        {
          title: "电商",
          imgSrc: require("../assets/images/home/hangye_2@2x.png"),
        },
        {
          title: "金融",
          imgSrc: require("../assets/images/home/hangye_3@2x.png"),
        },
        {
          title: "游戏",
          imgSrc: require("../assets/images/home/hangye_4@2x.png"),
        },
        {
          title: "零售",
          imgSrc: require("../assets/images/home/hangye_5@2x.png"),
        },
        {
          title: "保险",
          imgSrc: require("../assets/images/home/hangye_6@2x.png"),
        },
        {
          title: "教育",
          imgSrc: require("../assets/images/home/hengye_7@2x.png"),
        },
        {
          title: "地产",
          imgSrc: require("../assets/images/home/hengye_8@2x.png"),
        },
      ],

      title4: "图拓为以下企业提供服务",
      businessList: [
        {
          imgSrc: require("../assets/images/home/brand_1@2x.png"),
        },
        {
          imgSrc: require("../assets/images/home/brand_2@2x.png"),
        },
        {
          imgSrc: require("../assets/images/home/brand_3@2x.png"),
        },
        {
          imgSrc: require("../assets/images/home/brand_4@2x.png"),
        },
        {
          imgSrc: require("../assets/images/home/brand_5@2x.png"),
        },
        {
          imgSrc: require("../assets/images/home/brand_6@2x.png"),
        },
      ],
    };
  },
  methods: {
    demo() {
      let login = this.loginInfo.isLogin;
      if (login) {
        // 已登录
        api.loginTutor().then((res) => {
          if (res.data.status == 1) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
            }, 5000);
            // =-1001001
            let users = {
              token: res.data.fields.token,
              userId: res.data.fields.userId,
              userName: this.$cookies.get("userP"),
            };
            let userInfo =
              "?token=" +
              users.token +
              "&userId=" +
              users.userId +
              "&userName=" +
              users.userName;
            let url = /localhost|dev/.test(location.origin)
              ? "https://dev.tutordata.cn/"
              : /uat/.test(location.origin)
              ? "http://uat.tutordata.cn/"
              : "https://demo.tutordata.cn/";
            window.open(url + userInfo); //线上
          } else if (res.data.status == -1001001) {
            this.background("warning");
            this.$parent.loginOut();
          }
        });
      } else {
        // 未登录
        this.$emit("priceRegister", true);
      }
    },
    reg() {
      this.$emit("register", true);
    },
    background(type) {
      if (type == "warning") {
        this.$Message[type]({
          background: true,
          content: "登录过期,请重新登录",
        });
      }
    },
    // 关闭客服
    close() {
      this.seek = false;
      this.weixin = false;
    },
    // 打开客服
    seekShow() {
      this.seek = true;
      this.weixin = false;
    },
    //
    // 微信客服
    showWeiin() {
      this.seek = false;
      this.weixin = true;
    },
    seekqq() {
      window.open("http://wpa.qq.com/msgrd?v=3&uin=375049442&site=qq&menu=yes"); //线上
    },
  },
};
</script>

<style scoped>
.home-header {
  width: 100%;
  background: rgba(248, 249, 255, 1);
}

.home-header-container {
  height: 27.6rem;
  position: relative;
  overflow: hidden;
}

.home-header-container:after {
  content: "";
  width: 31.75rem;
  height: 23rem;
  background: url("../assets/images/bg_1.png") 0 / 100% no-repeat;
  display: block;
  position: absolute;
  top: 1.85rem;
  right: 5.6rem;
}

.home-header-container h1 {
  width: 23.25rem;
  height: 7.8rem;
  margin: 6.05rem 0 1.45rem;
  font-size: 3rem;
  font-weight: 600;
  color: rgba(24, 47, 57, 1);
  line-height: 3.9rem;
}
.home-header-container p {
  font-size: 0.95rem;
  font-weight: 400;
  color: #575a5f;
  line-height: 2rem;
}
.home-container {
  background: #fff;
  display: flex;
  height: 27.6rem;
  /* position: relative; */
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
}

.home-container h3 {
  width: 25rem;
  height: 2.5rem;
  font-size: 1.8rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 2.5rem;
}

.home-container img {
  /* content: ""; */
  width: 27rem;
  height: 23rem;
  display: block;
  /* position: absolute;
  top: 1.85rem;
  right: 5.6rem; */
}
.home-container p {
  width: 25rem;
  margin-top: 1.25rem;
  line-height: 2rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 34px;
}
.home-btn {
  display: flex;
  justify-content: space-between;
  width: 21rem;
  margin-top: 2.1rem;
}
.section-btn-demo,
.section-btn-reg {
  width: 9.3rem;
  height: 3.1rem;
  box-shadow: 0rem 0.25rem 0.5rem 0rem rgba(178, 95, 36, 0.24);
  border-radius: 0.2rem;
  font-size: 1rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 3.1rem;
  text-align: center;
  text-decoration: none;
  display: block;
  bottom: 4.65rem;
}
.section-btn-demo {
  background: #f28335;
  color: #ffffff;
}
.section-btn-reg {
  background: #ffffff;
  color: #f28335;
}
.home-container-trade {
  background: #fff;
  height: 35.6rem;
  overflow: hidden;
}
.home-container-trade .section-title {
  margin: 3.2rem 0 0 0;
}
.home-container-bus .section-title {
  margin: 0 0 3.65rem 0;
}

.con-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
}
.tradeText {
  font-size: 1rem;
  text-align: center;
  margin: 1rem 0 3.15rem 0;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 1.7rem;
}

.icon-item {
  width: 12.75rem;
  height: 9rem;
  background: #ffffff;
  box-shadow: 0px 0.25rem 0.75rem 0px rgba(116, 125, 132, 0.16);
  border-radius: 0.1rem;
  margin-top: 1.5rem;
  /* background-image: ; */
  background: url("../assets/images/home/bg@2x.png") 0 / 120% no-repeat;
  background-position: bottom;
}

.icon-item img {
  width: 4.15rem;
  display: block;
  margin: 1rem auto 1rem;
}

.icon-item h3 {
  height: 1.65rem;
  margin-bottom: 0.45rem;
  font-size: 1.2rem;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: rgba(33, 43, 54, 1);
  line-height: 1.45rem;
}

.icon-item p {
  height: 1.2rem;
  font-size: 0.75rem;
  font-family: OpenSans;
  color: rgba(99, 115, 129, 1);
  line-height: 1.2rem;
}
.home-container-bus {
  height: 31rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-container-start {
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.start {
  margin-top: 1.8rem;
}
.icon-item-bus {
  width: 14.3rem;
  height: 5.3rem;
  margin-top: 1.5rem;
  display: flex;
  background: #ffffff;
  box-shadow: 0rem 0.25rem 0.75rem 0rem rgba(116, 125, 132, 0.16);
  border-radius: 0.3rem;
  justify-content: center;
  align-items: center;
}
.icon-item-bus img {
  object-fit: cover;
  width: 8rem;
  height: 4rem;
}
.service {
  height: 0;
}
.serviceClose {
  position: fixed;
  bottom: 5.8rem;
  right: 16rem;
  width: 1.1rem;
  height: 1.1rem;
}
.service-box {
  position: fixed;
  bottom: 2.2rem;
  right: 5rem;
  width: 10.9rem;
  height: 4.8rem;
  background: url("../assets/images/home/矩形@2x.png") 0 / 100% no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.service-box h4 {
  font-size: 0.7rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 1rem;
}
.seek {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.seek img {
  width: 0.75rem;
  height: 0.7rem;
  margin-right: 0.24rem;
}
.weixin {
  position: fixed;
  bottom: 2.2rem;
  right: 5rem;
  width: 10.85rem;
  height: 9.1rem;
  background: url("../assets/images/home/矩形-weixin@2x.png") 0 / 100% no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.weixinClose {
  position: fixed;
  bottom: 10.1rem;
  right: 16rem;
  width: 1.1rem;
  height: 1.1rem;
}
.qrcode {
  width: 5.5rem;
  height: 5.5rem;
}
.weixin p {
  font-size: 0.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.85rem;
}
.serviceImg {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
.desTitle {
  font-size: 0.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.85rem;
}
</style>
